@font-face {
  font-family: "TedNext";
  font-style: normal;
  font-weight: 300;
  src: local("TedNext Light"), url(./assets/fonts/TedNext-Light.woff) format("woff2"),
    url(./assets/fonts/TedNext-Light.woff) format("woff");
}

@font-face {
  font-family: "TedNext";
  font-style: normal;
  src: local("TedNext"), url(./assets/fonts/TedNext-Regular.woff2) format("woff2"),
    url(./assets/fonts/TedNext-Regular.woff) format("woff");
}

@font-face {
  font-family: "TedNext";
  font-style: normal;
  font-weight: 600;
  src: local("TedNext Semibold"), url(./assets/fonts/TedNext-Semibold.woff2) format("woff2"),
    url(./assets/fonts/TedNext-Semibold.woff) format("woff");
}

@font-face {
  font-family: "TedNext";
  font-style: normal;
  font-weight: 700;
  src: local("TedNext Bold"), url(./assets/fonts/TedNext-Bold.woff2) format("woff2"),
    url(./assets/fonts/TedNext-Bold.woff) format("woff");
}


html {
  position: relative;
  min-height: 100%;
}

body {
  font-family: "TedNext", sans-serif !important;
  margin-bottom: 60px;
  height: 100%;
  color: #000000;
}



/* body {
  margin-bottom: 60px;
  height: 100%;
  font-family: "Ted Next" !important;
} */

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  cursor: pointer !important;
}

::-webkit-scrollbar-thumb {
  background: #6e6e6e;
  border-radius: 10px;
  cursor: pointer;
}

.title {
  font-weight: 500;
  font-size: 32px;
  color: #000000;
}

@media screen and (max-width: 600px) {
  .title {
    font-size: 28px !important;
    font-weight: 600 !important;
  }
}

.sub-title {
  font-weight: 500;
  font-size: 18px;
  color: #000000;
  line-height: 27px;
}

@media screen and (max-width: 600px) {
  .sub-title {
    font-size: 15px !important;
    color: #1F1F1F !important;
    line-height: 23px;
  }
}

.start_btn {
  background-color: #da291c !important;
  color: white !important;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.4px;
  min-width: 272px;
  height: 48px;
  font-weight: 600;
}



.continue_btn {
  background-color: #da291c !important;
  color: white !important;
  font-size: 16px;
  line-height: 24px;
  border-radius: 4px;
  letter-spacing: 0.4px;
  min-width: 198px;
  height: 48px;
  font-weight: 600;
}


.content {
  /* margin-bottom: 7rem; */
  overflow: auto;
}

@media screen and (max-width: 600px) {
  .content {
    /* margin-bottom: 10rem !important; */
    overflow: auto;
  }
}

.outline_btn {
  color: #da291c !important;
  font-size: 16px;
  line-height: 24px;
  border-radius: 4px;
  letter-spacing: 0.4px;
  min-width: 198px;
  min-height: 48px;
  border: 1px solid #da291c !important;
  font-weight: 600;
}

.back {
  color: #2f6fa7 !important;
  font-size: 16px !important;
  font-weight: 600;
  cursor: pointer;
}

.afternavbar {
  margin-top: 7rem !important;
  margin-bottom: 7rem;

}

@media screen and (max-width: 600px) {
  .afternavbar {
    margin-top: 5rem !important;
    margin-bottom: 11rem !important;

  }
}

a:hover {
  text-decoration: underline !important;
  cursor: pointer;
}

a {
  text-decoration: none !important;
}

.btn:focus,
.btn:active {
  outline: none !important;
  box-shadow: none;
}


/* spinner css */
.loading-spinner {
  position: fixed;
  z-index: 999;
  overflow: show;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
}

.loader-div {
  position: relative;
  top: 50%;
}

.loader-text {
  color: white;
  font-size: 24px;
  padding-left: 11px;
  font-weight: 500;
}

#loading {
  display: inline-block;
  width: 33.33px;
  height: 33.33px;
  border: 5px solid #E7A1A1;
  border-radius: 50%;
  border-top-color: #DA291C;
  animation: spinner 2s linear infinite;
  -webkit-animation: spin 1s ease-in-out infinite;
  color: white;

}

.load {
  display: inline-flex;
}

@media screen and (max-width: 600px) {
  .load {
    display: inline-flex;
    align-content: flex-end;
    flex-direction: column;
  }

  #loading {
    margin: auto;
  }

  .loader-text {
    padding-left: 30px;
  }
}

@keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}

.stepper {
  display: flex;
  justify-content: space-between;
  padding: 0 3px;
  margin-bottom: 6px !important;
}

.stepper .step_count {
  color: #414042;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  margin-bottom: 0px !important;
}

.stepper .step_name {
  color: #414042;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  margin-bottom: 0px !important;

}



ul.checkmark li {
  font-size: 14px;
  margin-bottom: 1em;
  list-style-type: none;
  padding: 0.25em 5px 0 2.8em;
  position: relative;
  font-weight: 500;
  line-height: 24px;
  color: #1F1F1F;
}

ul.checkmark li:before {
  content: " ";
  display: block;
  /* border: solid 0.8em red; */
  border-radius: 0.8em;
  height: 0;
  width: 0;
  position: absolute;
  left: 0.5em;
  top: 40%;
  margin-top: -0.5em;
}

ul.checkmark li:after {
  content: " ";
  display: block;
  width: 9px;
  height: 19px;
  border: solid #1E8240;
  border-width: 0 0.2em 0.2em 0;
  position: absolute;
  left: 1em;
  top: 40%;
  margin-top: -7px;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

.ekyc_title {
  font-weight: 500;
  font-size: 24px;
  line-height: 31px;
  color: #1F1F1F;
}

.ekyc_sub_title {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #1F1F1F;
}

.success_alerts {
  margin-top: 60px;
  min-height: 62px;
}

.alert-heading {
  color: #0E3B1D;
  font-size: 16px !important;
  font-weight: 600 !important;

}


